const menus = [
    {
        title: "Home", route: "home"
    },
    {
        title: "About", route: "about"
    },
    {
        title: "Product", route: "product"
    },
    {
        title: "Services", route: "service"
    },
    {
        title: "Gallery", route: "gallery"
    },
    {
        title: "Events", route: "event"
    },
    {
        title: "Contact Us",
        route: "contact"
    }
]

export default menus