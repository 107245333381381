<template>
  <div>
    <div class="d-none d-md-block">
      <navbar />
    </div>
    <div class="d-md-none d-block">
      <mobile-navbar />
    </div>
    <v-main>
      <router-view></router-view>
    </v-main>
    <Footer />
  </div>
</template>

<script>
import Footer from "./public/Footer.vue";
import Navbar from "./public/Navbar.vue";
import MobileNavbar from "./public/MobileNavbar.vue";
export default {
  data() {
    return {};
  },
  components: {
    Footer,
    Navbar,
    MobileNavbar,
  },
};
</script>