<template>
  <div>
    <v-app-bar
      app
      flat
      class="d-none d-md-block"
      style="border-bottom: 1px solid #e6e6e6; background-color: white"
    >
      <v-avatar size="60" style="cursor: pointer" @click="$router.push('/')">
        <img src="../../assets/logo.jpg" alt="logo" class="mr-1" />
      </v-avatar>
      <v-toolbar-title
        class="title primaryDark--text"
        style="cursor: pointer"
        @click="$router.push('/')"
        >Tesfa Coffee</v-toolbar-title
      >

      <v-spacer></v-spacer>
      <v-text-field
        prepend-inner-icon="mdi-magnify"
        solo
        rounded
        flat
        dense
        hide-details
        label="Search"
        color="primary"
        background-color="#edf2f7"
      />
      <v-spacer></v-spacer>

      <v-btn
        text
        class="text-capitalize"
        v-for="link in links"
        :key="link.title"
        @click="$router.push({ name: link.route })"
      >
        {{ link.title }}
      </v-btn>
    </v-app-bar>
  </div>
</template >

<script>
import menus from "../../data/menu";
export default {
  data() {
    return {
      links: menus,
    };
  },
};
</script>