<template>
  <div>
    <v-app-bar
      flat
      class="d-block d-md-none"
      app
      clipped-left
      clipped-right
      fixed
      style="border-bottom: 1px solid #e6e6e6; background-color: white"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <img src="../../assets/logo.jpg" alt="logo" height="35px" class="mr-1" />

      <v-text-field
        append-icon="mdi-magnify"
        solo
        rounded
        flat
        dense
        hide-details
        label="Search"
        color="primary"
        background-color="#edf2f7"
      />
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" app>
      <v-toolbar
        flat
        dense
        style="border-bottom: 2px solid #f6f7f9; background-color: white"
      >
        <img
          src="../../assets/logo.jpg"
          alt="logo"
          height="35px"
          class="mr-1"
        />
        <v-toolbar-title class="primaryDark--text title"
          >Tesfa Coffee</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn icon @click="drawer = !drawer">
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </v-toolbar>

      <v-list dense>
        <template v-for="menu in menus">
          <v-list-item
            v-if="!menu.children"
            :key="menu.title"
            @click="$router.push({ name: menu.route })"
          >
            <v-list-item-title
              >{{ menu.title }}
            </v-list-item-title></v-list-item
          >
        </template>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
import menus from "../../data/menu";
export default {
  data() {
    return {
      drawer: false,
      menus: menus,
    };
  },
};
</script>
